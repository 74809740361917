@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Brand Colors */
  --color-primary: #000eef;
  --color-secondary: #69dbff;
  --color-tertiary: #e5e7fd;

  /* Main Colors */
  --color-primary-text: #111928;
  --color-secondary-text: #6e747d;
  --color-stroke: #e5e7eb;

  /* Dark Colors */
  --color-dark: #111928;
  --color-dark-2: #1f2a37;
  --color-dark-3: #374151;
  --color-dark-4: #4b5563;
  --color-dark-5: #6b7280;
  --color-dark-6: #9ca3af;
  --color-dark-7: #d1d5db;
  --color-dark-8: #e5e7eb;

  /* White Colors */
  --color-white: #ffffff;

  /* Gray Colors */
  --color-gray-1: #f9fafb;
  --color-gray-2: #f3f4f6;
  --color-gray-3: #e5e7eb;
  --color-gray-4: #dee2e6;
  --color-gray-5: #ced4da;
  --color-gray-6: #ced4da;
  --color-gray-7: #ced4da;

  /* Yellow Colors */
  --color-yellow: #fbbf24;
  --color-yellow-1: #fff3bf;
  --color-yellow-dark: #f59e0b;
  --color-yellow-dark-2: #d97706;
  --color-yellow-light: #fcd34d;
  --color-yellow-light-2: #fde68a;
  --color-yellow-light-3: #fef3c7;
  --color-yellow-light-4: #fffbeb;

  /* Orange Colors */
  --color-orange: #f27430;
  --color-orange-dark: #e1580e;
  --color-orange-light: #f59460;
  --color-orange-light-2: #f8b490;
  --color-orange-light-3: #fbd5c0;
  --color-orange-light-4: #fde5d8;
  --color-orange-light-5: #fff0e9;

  /* Red Colors */
  --color-red: #f23030;
  --color-red-dark: #e10e0e;
  --color-red-0: #fff5f5;
  --color-red-9: #c92a2a;
  --color-red-light: #f56060;
  --color-red-light-2: #f89090;
  --color-red-light-3: #fbc0c0;
  --color-red-light-4: #fdd8d8;
  --color-red-light-5: #feebeb;
  --color-red-light-6: #fef3f3;

  /* Pink Colors */
  --color-pink: #d345f8;
  --color-pink-dark: #c814f6;
  --color-pink-light: #df76fa;
  --color-pink-light-2: #eaa7fc;
  --color-pink-light-3: #f6d8fe;
  --color-pink-light-4: #fcf1fe;

  /* Purple Colors */
  --color-purple: #8646f4;
  --color-purple-dark: #6d28d9;
  --color-purple-dark-2: #5b21b6;
  --color-purple-light: #a78bfa;
  --color-purple-light-2: #c4b5fd;
  --color-purple-light-3: #ddd6fe;
  --color-purple-light-4: #ede9fe;
  --color-purple-light-5: #f5f3ff;

  /* Blue Colors */
  --color-blue: #2d68f8;
  --color-blue-dark: #1c3fb7;
  --color-blue-light: #5475e5;
  --color-blue-light-2: #8099ec;
  --color-blue-light-3: #adbcf2;
  --color-blue-light-4: #c3cef6;
  --color-blue-light-5: #e1e8ff;

  /* Cyan Colors */
  --color-cyan: #01a9db;
  --color-cyan-dark: #0b76b7;
  --color-cyan-light: #18bfff;
  --color-cyan-light-2: #77d1f3;
  --color-cyan-light-3: #d0f0fd;

  /* Teal Colors */
  --color-teal: #02aaa4;
  --color-teal-dark: #06a09b;
  --color-teal-light: #20d9d2;
  --color-teal-light-2: #72ddc3;
  --color-teal-light-3: #c2f5e9;

  /* Green Colors */
  --color-green: #22ad5c;
  --color-green-dark: #1a8245;
  --color-green-light: #2cd673;
  --color-green-light-2: #57de8f;
  --color-green-light-3: #82e6ac;
  --color-green-light-4: #acefc8;
  --color-green-light-5: #c2f3d6;
  --color-green-light-6: #daf8e6;

  /* Violet Colors */
  --color-violet-0: #f3f0ff;
}

html {
  /* height: 100%;
  display: flex;
  flex-direction: column; */
  /* scrollbar-gutter: stable; */
}

.v2-global {
  font-family: 'Figtree', sans-serif;
}

.products-list-scrollbar {
  overflow-y: auto;
}

/* Webkit browsers */
.products-list-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.products-list-scrollbar::-webkit-scrollbar-track {
  background: #f8f9fa;
  border-radius: 4px;
}

.products-list-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.products-list-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ced4da;
}

/* Firefox */
.products-list-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #dee2e6 #f8f9fa;
}
