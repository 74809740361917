.heroui-table tbody tr {
  @apply cursor-pointer h-16 hover:bg-gray-2 transition-background border-b border-gray-4;
}

.heroui-table thead th {
  @apply bg-white border-b border-gray-4;
  @apply text-base tracking-[-0.14px] leading-[150%] font-medium text-secondary-text;
}

.heroui-table tbody tr td {
  @apply !px-4 py-0;
}

.heroui-table tbody tr td:first-child,
.heroui-table tbody tr td:last-child {
  @apply !px-0;
}

.heroui-table thead td {
  @apply !px-4 py-0;
}

.heroui-table thead th:first-child,
.heroui-table thead th:last-child {
  @apply !px-0;
}
