.animated-points {
  display: flex;
}

.point {
  animation: bounce 1.2s infinite ease-in-out;
}

/* Escalonamos la animación */
.point:nth-child(1) {
  animation-delay: 0s;
}

.point:nth-child(2) {
  animation-delay: 0.2s;
}

.point:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  30% {
    transform: translateY(0); /* Comienza y se queda en el centro */
  }
  40% {
    transform: translateY(-8px); /* Sube */
  }
  50%,
  100% {
    transform: translateY(0); /* Vuelve al centro más rápido */
  }
}
